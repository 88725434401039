<template>
  <errorLayout v-if="isErrorLayout" :error="{ status: errorStatus }"></errorLayout>
  <virtual-shelf v-else-if="isVirtualShelfLayout" />
  <component :is="components[layout]" v-else-if="!$isEmpty(layout)" :key="layout"></component>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHead } from '@unhead/vue';

import virtualShelfComposables from '@/composables/utility/virtual-shelf';
import { useLayoutErrorStore } from '@/stores/layout-error';
import { layoutErrorStatusEnum } from '@/router/layout';
import { getDefaultMeta } from '@/helper/meta';
import { importDefaultGoogleFonts } from '@/helper/meta/google-fonts';
import { isEmptyValue } from '@/helper/data-process';

const errorLayout = defineAsyncComponent(() => import('@/layouts/error.vue'));
const VirtualShelf = defineAsyncComponent(() => import('@/layouts/virtual-shelf.vue'));

const components = {
  blankLayout: defineAsyncComponent(() => import('@/layouts/blank.vue')),
  cartLayout: defineAsyncComponent(() => import('@/layouts/cart.vue')),
  defaultLayout: defineAsyncComponent(() => import('@/layouts/default.vue')),
  errorLayout,
  esliteLayout: defineAsyncComponent(() => import('@/layouts/eslite.vue')),
  exhibitionLayout: defineAsyncComponent(() => import('@/layouts/exhibition.vue')),
  giftAiLayout: defineAsyncComponent(() => import('@/layouts/gift-ai.vue')),
  home2024indexLayout: defineAsyncComponent(() => import('@/layouts/home-2024index.vue')),
  home2024mobileLayout: defineAsyncComponent(() => import('@/layouts/home-2024mobile.vue')),
  homeBaseLayout: defineAsyncComponent(() => import('@/layouts/home-base.vue')),
  homeLayout: defineAsyncComponent(() => import('@/layouts/home.vue')),
  homeMemberProfileLayout: defineAsyncComponent(() => import('@/layouts/home-member-profile.vue')),
  marketLayout: defineAsyncComponent(() => import('@/layouts/market.vue')),
  testonlyLayout: defineAsyncComponent(() => import('@/layouts/test-only.vue')),
  VirtualShelf,
};

const route = useRoute();

// ?layout=virtual_shelf
const { isVirtualShelfLayout } = virtualShelfComposables();
const layoutErrorStore = useLayoutErrorStore();
const { getErrorStatus } = storeToRefs(layoutErrorStore);

/**
 * Computed
 */
const errorStatus = computed(() => getErrorStatus.value);
const isErrorLayout = computed(() => errorStatus.value !== null);
const layoutKey = computed(() => route.meta.layout || '');
const hasLayout = computed(() => !isEmptyValue(layoutKey.value) && Object.keys(components).includes(`${layoutKey.value}Layout`));
const layout = computed(() => (hasLayout.value ? `${layoutKey.value}Layout` || 'defaultLayout' : ''));

/**
 * Methods
 */
const setErrorStatus = (error) => layoutErrorStore.setErrorStatus(error);
const checkIsUnknownRoute = (route) => {
  if (route.path === '/' || isEmptyValue(route.path)) return false;
  return isEmptyValue(route.name);
};

const metaInfo = function () {
  // 預先載入字體，避免字體閃爍
  // 不需要根據路由判斷，直接在應用啟動時載入會更好
  importDefaultGoogleFonts();

  // 如果是首頁，才需要額外的 meta 資訊
  return route.name === 'index' ? getDefaultMeta() : {};
};
useHead(metaInfo());

watch(
  route,
  (val) => {
    if (checkIsUnknownRoute(val)) {
      setErrorStatus(layoutErrorStatusEnum.error404);
    }
  },
  {
    immediate: true,
  },
);
// 頁面載入完成後動態載入 SCSS
onMounted(() => {
  // 使用 requestAnimationFrame 確保渲染完成
  requestAnimationFrame(() => {
    // 延遲載入次要 SCSS 檔案
    setTimeout(() => {
      import('@/assets/google-fonts-CJK.scss');
    }, 500); // 延遲 500 毫秒載入
  });
});
</script>

<script>
export default { name: 'App' };
</script>
