import { isEmptyValue } from '@/helper/data-process';

/**
 * 取得館別路徑
 */
export const getCategoryLinkTo = ({ isBrand, depth, id, parentId }) => {
  if (isBrand === true) {
    switch (depth) {
      case 2:
        return `/brand/${id}`;
      case 3:
        return `/brand/${parentId}/${id}`;
      // 1 或是其他
      default:
        return `/brand`;
    }
  }
  return `/category/${depth}/${id}`;
};

/**
 * 取得館別路徑
 * @param {object} item category item
 * @return {string} url path
 */
export const categoryLinkTo = (item) => {
  if (!isEmptyValue(item.link)) {
    return item.link;
  }
  return getCategoryLinkTo({ isBrand: item.is_brand, depth: item.depth, id: item.id, parentId: item.parent_id });
};

/**
 * @const {function} getURL 取得網址物件 (for 舊瀏覽器)
 * @param {string} href url path
 * @param {string} href url base (example: window.location.origin)
 * @return {object} URL Object
 * */
export const getURL = (href = '', base = '') => {
  if (typeof URL !== 'undefined') {
    try {
      return base ? new URL(href, base) : new URL(href);
    } catch (e) {
      return null;
    }
  }

  // 確保支援舊瀏覽器
  const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

  return match
    ? {
        href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4] || '',
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    : null;
};

/**
 * get query string object with url
 * @param {string} url url
 * @returns {object} query object
 */
export const getQueryParams = (url) => {
  const queryString = url.slice(url.indexOf('?') + 1);
  if (queryString === url) return {};

  return queryString.split('&').reduce((acc, param) => {
    const [key, value] = param.split('=');
    return { ...acc, [decodeURIComponent(key)]: decodeURIComponent(value) };
  }, {});
};

/**
 * obj to query string
 * @param {object} obj query string object
 * @returns {string} query string url
 */
export const objectToQueryString = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
